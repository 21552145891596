import React from 'react';
import Section from "../../../components/ui/Section/Section";
import Layout from '../../../components/Layout';
import { Link } from 'gatsby';
import Illustration from '../../../assets/karriere.svg';
import * as styles from '../job-karriere.module.scss';
import classnames from 'classnames';

export default function Bueroazubi () {

    return <Layout crumbs={[
        { label: "Home", url: "" }, 
        { label: "Unternehmen", url: "unternehmen" }, 
        { label: "Karriere", url: "jobs-karriere" }, 
        { label: "Kauffrau / Kaufmann für Büromanagement (Azubi)", url: "Bueroazubi" }
        ]} headerColor="dark" hasWave={false} breadcrumbColor="dark">


        <Section>
            <div className="columns">
                <div className="column is-1 is-hidden-touch"></div>
                <div className={classnames(["column", styles.illustration])}>
                    <img src={Illustration} alt="Karriere, bist du das passende Puzzelteil?" />
                </div>
                <div className="column is-half-desktop">
                    <div className="content">
                        <h2 className="title">Ausbildung zur/m Kauffrau / Kaufmann für Büromanagement (m/w/d)</h2>
                        <h3 className="subtitle">Ausbildung</h3>
                            
                        <p>
                            <strong> Du bringst gerne Struktur ins Chaos, liebst es zu organisieren und bringst Ordnung in einen Zahlendschungel? 
                            Dann bist du bei uns genau richtig!</strong>
                        </p>
                        <p>
                            Komm in ein dynamisches, kompetentes und motiviertes Team! Wir sind sind HIS Print & Service. Seit nun über 30 Jahren haben wir uns alles Wissen im Bereich der Werbetechnik und Beschriftung, wie Schilder oder Aufkleber in unterschiedlichsten Anwendungen, angeeignet. Unsere Kunden kommen sowohl von der Industrie, als auch vom Handwerk. Sowohl Firmen als auch Privatpersonen werden bei uns kompetent beraten und mit der besten Lösung beliefert. 
                        </p>

                        <p>
                            Bist du bereit für einen bunten Aufgabenmix? Dann bewirb dich!
                        </p>
                        
                        <p>
                            <strong>Deine Aufgaben</strong>
                            <ul>
                                <li>Kundenbetreuung</li>
                                <li>Mitwirkung bei Angebots- und Auftragsabwicklung (Buchhaltung, Kostenrechnung, Controlling)</li>
                                <li>Allgemeine Büroorganisation, bspw. Fakturierung</li>
                                <li>Pflege der digitalen Kundenkarteien</li>
                                <li>Interne Datenbereitstellung und Datenaufbereitung</li>
                                <li>Gestalterischen Freiraum und Möglichkeit zur Grafikgestaltung</li>
                            </ul>
                        </p>
                        <p>
                            <strong>Dein Profil</strong>
                            <ul>
                                <li>Mittlere Reife</li>
                                <li>Freude im Umgang mit Menschen: kommunikativ und kontaktfreudig</li>
                                <li>Spaß an Computerarbeit</li>
                                <li>Schnelle Auffassungsgabe</li>
                                <li>Interesse an kaufmännischen Zusammenhängen</li>
                                <li>Aufgeschlossener, kommunikationsstarker Teamplayer mit Lust auf Verantwortung</li>
                                <li>Teamorientierung, Flexibilität, Organisationsfähigkeit</li>
                            </ul>
                        </p>
                        <p>
                            <strong>Wir bieten</strong>
                            <ul>
                                <li>Individuelle und intensive Betreuung durch Ausbildungsleitung </li>
                                <li>Ein ausgezeichnetes Betriebsklima mit Off-Site Events</li>
                                <li>Möglichkeit zur Grafikgestaltung durch Zusammenarbeit mit unserer Mediengestalterin</li>
                                <li>30 Tage Urlaub</li>
                                <li>Interessante und abwechslungsreiche Aufgaben</li>
                            </ul>
                        </p>

                        
                        <h3>Starte ab September 2023 mit uns durch!</h3>
                        <p>
                            Schicke uns jetzt deine Bewerbung an <a href="mailto:bewerbung@hisprint.de">bewerbung@hisprint.de</a>
                        </p>

                        <p>
                            <h3>Lerne uns schon mal genauer kennen:</h3>
                            <ul>
                                <li><Link to="/produkte">Unsere alltäglichen Produkte</Link></li>
                                <li><Link to="/fallbeispiele">Fallbeispiele von Einzelprojekten</Link></li>
                                <li><Link to="/unternehmen">Lerne unser Team jetzt schon kennen</Link></li>
                            </ul>
                        </p>

                    </div>
                </div>

            </div>
        </Section>
    </Layout>
}
